import {Component, Input}   from '@angular/core';
import {PageComponentModel} from "../../../models/page";

@Component({
    selector   : 'app-block-generator',
    templateUrl: './block-generator.component.html',
})
export class BlockGeneratorComponent {
    @Input() page: PageComponentModel | null = null;
    @Input() container: boolean              = true;
}
