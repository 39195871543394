<div [class]="'z-1 relative' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <div class="grid">
                <div class="col-12 lg:col-6 flex align-items-center justify-content-center">
                    <ng-container *ngFor="let tab of block.blocks; let index = index">
                        <app-asset *ngIf="activeTab == index" [block]="tab" [theme]="theme"></app-asset>
                    </ng-container>
                </div>
                <div class="col-12 lg:col-6 mt-5 lg:mt-0">
                    <ul class="list-none m-0 p-0">
                        <ng-container *ngFor="let tab of block.blocks; let index = index">
                            <li (mouseover)="activeTab = index" [ngClass]="{'border-secondary-light': activeTab !== index, 'bg-brand-lightest border-brand-color': activeTab === index}"
                                class="border-left-2 p-5">
                                <div [innerHTML]="tab.title | safeHTML"
                                     class="font-bold line-height-3 text-2xl mb-2 [&>p]:mb-0 [&>p]:mt-0 text-primary"></div>
                                <div [innerHTML]="tab.content_limited | safeHTML"
                                     class="[&>p]:break-words [&>p]:mb-0 [&>p]:mt-0 [&>a]:text-primary [&>a]:no-underline [&>a]:font-bold line-height-3 text-700"></div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
