import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer}        from '@angular/platform-browser';

@Pipe({name: 'safeHTML'})
export class SafeHTMLPipe implements PipeTransform {
    constructor(
        private readonly sanitizer: DomSanitizer
    ) {
    }

    transform(content: string) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
}
