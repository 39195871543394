import {Component, OnChanges} from '@angular/core';
import {Block}                from "../../block";
import {BlockComponentModel}  from "../../../models/block";

@Component({
    selector   : 'app-blog-grid',
    templateUrl: './blog-grid.component.html',
})
export class BlogGridComponent extends Block implements OnChanges {
    blogItems: BlockComponentModel[]        = []
    customBlock: BlockComponentModel | null = null

    protected override onChange() {
        super.onChange();

        this.customBlock = {
            ...this.block,
            blocks: this.block.blocks.filter(b => b.type !== 'buttonComponent')
        };

        this.blogItems   = this.block.blocks.filter(b => b.type !== 'buttonComponent');
    }
}
