import {Component, Input} from '@angular/core';

@Component({
    selector   : 'app-empty-search',
    templateUrl: './empty-search.component.html',
})
export class EmptySearchComponent {
    @Input() searchEmpty: boolean = false;

    @Input() width: string  = '339'
    @Input() height: string = '259'
}
