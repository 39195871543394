<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTextTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme === 'dark'}">
            <div class="rounded-2xl">
                <div class="grid justify-content-between">
                    <div class="col-12 lg:col-6 mb-3">
                        <div class="h-full pl-0">
                            <app-asset *ngIf="block.asset" [block]="block" [theme]="theme"
                                       blockClass="mb-5 md:mb-16"></app-asset>
                            <div [innerHTML]="block.content | safeHTML"
                                 class="[&>p]:text-800 [&>p]:text-sm [*>b]:text-brand-color"></div>
                            <app-text [block]="block" [theme]="theme" class="font-normal text-sm"></app-text>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="h-full">
                            <div class="flex flex-wrap w-full h-full" style="border-radius: 6px">
                                <div *ngFor="let gridBlock of gridBlocks; let last = last" class="mb-3 col-4">
                                    <div *ngIf="gridBlock.icon && gridBlock.icon.value !== 'noIcon'"
                                         class="flex justify-content-start mb-1">
                                        <app-icon [icon]="gridBlock.icon.value" class="text-900 text-base text-primary"></app-icon>
                                    </div>
                                    <div *ngIf="gridBlock.content" class="line-height-3 mb-3 [*>b]:text-brand-color [&>p]:mb-0 [&>p]:mt-0 [&>h3]:mb-1 [&>h3]:mt-2 [&>p]:text-700 [&>p]:text-sm [*>b]:text-brand-color"
                                         [innerHTML]="gridBlock.content | safeHTML"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
