export enum ContentType {
    Text       = 'contentComponent',
    List       = 'checkedRepeaterComponent',
    Asset      = 'assetComponent',
    Boxed      = 'boxedContentComponent',
    Review     = 'review',
    Limited    = 'contentLimitedComponent',
    Newsletter = 'newsletter',
}

export interface Dropdown {
    label: string
    value: any
    selected: boolean
    valid: boolean
}

export interface Category {
    slug: string;
    title: string;
    related_count: number;
}

export interface Tag {
    id: string;
    slug: string;
    title: string;
}

export interface Row {
    col1: string;
    col2: string;
    key: string;
    value: string;
}

export interface Asset {
    id: number
    url: string;
    kind: string
    size: number
    width: number
    height: number
    filename: string
    mimeType: string
    extension: string
    dateModified: string
}

export interface ThemeOption {
    card: string
    boxed: string
    button: string
    content: string
    textTitle: string
    newsletter: string
    breadcrumbs: string
    textSubtitle: string
    textSubtitleContrast: string;
}

export interface SEO {
    title: string
    description: string;
    advanced: {
        canonical: "https://www.google.com",
        robots: []
    };
    keywords: string[];
    social: {
        twitter: {
            handle: string;
            title: string;
            image: string;
            description: string;
        },
        facebook: {
            handle: string;
            title: string;
            image: string;
            description: string;
        }
    };
    canonical: {
        locale: string;
        uri: string;
    }[]
}

export interface JobResponse {
    total: number;
    data: Job[];
}

export interface Job {
    id: string
    status: number
    title: string
    titleSlug: string
    content: string
    jobStatus: number
    hoursPerWeekMin: number
    hoursPerWeekMax: number
    startDate: string
    endDate: string
    location: string
    locationSlug: string
    permanentJob: boolean
    client: any
    clientId: string
    clientName: string
    clientNameSlug: string
    hasClientLogo: boolean
    clientLogoContentType: string
    clientLogo: string
    recordId: string
    portalId: string
    entity: string
    broker: string
    brokerSlug: string
    recruiter: any
    recruiterId: string
    recruiterSlug: string
    recruiterFirstName: string
    recruiterLastName: string
    recruiterMiddleName: string
    recruiterInitials: string
    recruiterEmail: string
    recruiterPhoneNumber: string
    hasRecruiterImage: boolean
    recruiterImage: string
    recruiterImageContentType: string
    createdAt: string
    updatedAt: string
    source: string
    publishedDate: string
    extendable: boolean
    hasMaxRate: boolean
    numberOfPositions: number
    hourlyRateClient: number
    closingDateInvoice: string
    closingDateClient: string
    referenceCode: string
    referenceCodeClient: string
    regionLocation: {
        type: string
        coordinates: number[]
    }
    brokerUrl: string
    segmentName: string
    requesterEmail: string
    procedureSpecification: string
    procedureSpecificationName: string
}
