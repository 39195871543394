<a [href]="block.url" class="bg-white-alpha-60 shadow-2 h-full no-underline block" style="border-radius: 10px">
    <div [style]="'background-image:url(' + (block.asset_webp?.url ? block.asset_webp.url : block.asset?.url) + '); border-top-left-radius: 10px; border-top-right-radius: 10px;'"
         class="h-8rem w-full bg-cover bg-no-repeat"></div>

    <div class="p-4">
        <div class="flex">
            <p-tag class="mb-3 block uppercase" severity="success"
                   *ngFor="let category of block.categories; let last = last;"
                   [ngClass]="{'mr-3': !last}">{{ category.title }}
            </p-tag>
        </div>
        <p class="mt-0 mb-3 text-xl font-semibold text-900">{{ block.title }}</p>

        <div class="[&>div>p]:break-words [&>div>p]:mb-0 [&>div>p]:mt-0 [&>div>p]:line-clamp-3">
            <div [innerHTML]="block.content | safeHTML"
                 class="[&>p]:break-words [&>p]:mb-0 [&>p]:mt-0 line-height-3 text-700 [&>p]:line-clamp-3"></div>
        </div>
    </div>
</a>

