import {AfterViewInit, Component} from '@angular/core';
import {Block}                    from "../../block";

@Component({
    selector   : 'app-faq',
    templateUrl: './faq.component.html',
})
export class FaqComponent extends Block implements AfterViewInit {
    animation = '0ms';


    ngAfterViewInit() {
        this.animation = '150ms cubic-bezier(0, 0, 0.2, 1)';
    }
}
