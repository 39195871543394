import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-boxed',
    templateUrl: './boxed.component.html',
})
export class BoxedComponent extends Block {

}
