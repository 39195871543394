import {Component}           from '@angular/core';
import {Block}               from "../../block";
import {returnComponents}    from "../../../models/util";
import {BlockComponentModel} from "../../../models/block";

@Component({
    selector   : 'app-grid-block',
    templateUrl: './grid-block.component.html',
})
export class GridBlockComponent extends Block {
    gridBlocks: BlockComponentModel[] = [];

    protected override onChange() {
        super.onChange();

        this.gridBlocks = returnComponents(this.block, true, 'gridItem') as BlockComponentModel[];
    }
}
