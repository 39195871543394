<div>
    <div class="text-900 text-left text-xl font-medium mb-3">{{block.title}}</div>
    <div *ngIf="block.title_tag" class="text-xs text-left">
        <span class="rounded-[6px] border-1 border-primary p-1 text-primary">{{block.title_tag}}</span>
    </div>
    <div class="text-600 font-normal flex flex-wrap">
        <span class="text-left flex items-center [&>p]:m-1 [&>p]:flex [&>p]:items-center [&>p>*]:m-1 [&>p>strong]:text-2xl [&>p>del+strong]:text-primary [&>p>strong]:font-semibold [&>p>del]:text-2xl [&>p>del]:font-semibold [&>p>del]:text-gray-500 [&>p>del]:decoration-gray-500" [innerHTML]="block.content_limited | safeHTML">
        </span>
        <span *ngIf="block.text" class="text-left flex items-center"><i class="pi pi-info-circle text-600 ml-0.5" [pTooltip]="block.text"></i></span>
    </div>
    <ng-container *ngFor="let button of buttons">
        <app-button *ngIf="button.title" [block]="button" [theme]="theme"
                    blockClass="w-full p-button-outlined"></app-button>
    </ng-container>
    <div *ngIf="block.show_basic" class="mt-2 text-left text-xs text-800">
        Je ontvangt alle <br /><a href="#" class="text-xs text-800 decoration-gray-800">basisvoordelen</a> +
    </div>
</div>
