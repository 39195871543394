import {Component, SimpleChanges} from '@angular/core';
import {Block}                    from "../../block";
import {returnComponents, Theme}  from "../../../models/util";
import {Router}                   from "@angular/router";
import {TranslateService}         from "@ngx-translate/core";
import {LocalizeRouterService}    from "@gilsdav/ngx-translate-router";
import {BlockComponentModel}      from "../../../models/block";

@Component({
    selector   : 'app-job-search-header',
    templateUrl: './job-search-header.component.html',
})
export class JobSearchHeaderComponent extends Block {
    override theme = new Theme('dark');

    query: string = '';

    hours: { label: string, value: string }[] = [
        {label: '24u', value: '24'},
        {label: '36u', value: '36'},
        {label: '40u', value: '40'},
    ]
    selectedHour: string                      = '';

    jobs: BlockComponentModel[]                        = [];
    jobHeaderSearchContent: BlockComponentModel | null = null;

    constructor(
        private readonly router: Router,
        private readonly localize: LocalizeRouterService,
        private readonly translate: TranslateService
    ) {
        super();
    }

    override ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if (!changes['block'].currentValue) {
            return;
        }

        this.jobs                   = returnComponents(this.block, true, 'Job') as BlockComponentModel[];
        this.jobHeaderSearchContent = returnComponents(this.block, false, 'jobHeaderSearchContent') as BlockComponentModel;
    }

    navigateSearch() {
        const currentLang = this.translate.currentLang;

        let queryParams: any = {};
        if (this.query) {
            queryParams['query'] = this.query;
        }
        if (this.selectedHour) {
            queryParams['hours_per_week_max'] = this.selectedHour;
        }

        this.router.navigate([this.localize.translateRoute(`${(currentLang + '/')}opdrachten`)], {
            queryParams
        });
    }

    navigateJob(id: string) {
        const currentLang = this.translate.currentLang;
        this.router.navigate([this.localize.translateRoute(`${(currentLang + '/')}opdrachten`)], {
            queryParams: {
                id
            }
        });
    }
}
