import {Component}    from '@angular/core';
import {Block}        from "../../block";
import {DomSanitizer} from '@angular/platform-browser';
import {Theme} from "../../../models/util";

@Component({
    selector   : 'app-hero-tiles',
    styleUrl   : './hero-tiles.component.scss',
    templateUrl: './hero-tiles.component.html',
})
export class HeroTilesComponent extends Block {

    safeURL = this.sanitizer.bypassSecurityTrustUrl('javascript:void(0)');
    constructor(private sanitizer: DomSanitizer) {
        super();
    }
}
