import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {Block}                                          from "../../block";

@Component({
    selector   : 'app-asset',
    templateUrl: './asset.component.html',
})
export class AssetComponent extends Block {
    @ViewChild('video') videoElement!: ElementRef<HTMLVideoElement>;

    ended     = false;
    mouseOver = false;

    @HostListener('mouseenter')
    onMouseEnter() {
        this.mouseOver = true;
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.mouseOver = false;
    }

    toggleVideo(): void {
        if (this.videoElement.nativeElement.paused) {
            this.videoElement.nativeElement.play();
            return;
        }

        this.videoElement.nativeElement.pause();
    }
}
