<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <p-accordion [activeIndex]="0" styleClass="mb-6">
                <p-accordionTab *ngFor="let accordion of block.blocks" [header]="accordion.text" [transitionOptions]="animation">

                    <div [innerHTML]="accordion.content_limited | safeHTML" class="line-height-3"></div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
