<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <div class="grid">
                <div class="col-12 lg:col-3 xl:col-6">
                    <app-text [block]="block" [theme]="theme"></app-text>
                </div>

                <div *ngFor="let component of components" class="col-12 lg:col-3 xl:col-3">
                    <div class="flex">
                        <app-icon [icon]="component.icon.value" class="mr-3 text-4xl text-primary-400"></app-icon>

                        <div>
                            <p class="mt-0 mb-2 font-bold">{{ component.title }}</p>
                            <div [innerHTML]="component.content_limited | safeHTML"
                                 class="[&>p]:mb-0 [&>p]:mt-0 line-height-3"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overflow-hidden border-round">
                <google-map [options]="options" width="100%">
                    <map-marker [draggable]="false" [position]="latLng"/>
                </google-map>
            </div>
        </div>
    </div>
</div>
