<div [class]="(blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' )">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0 h-auto pb-7 lg:!h-[600px]">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div class="pt-7 md:px-6 lg:px-8 xl:!px-42 h-full">
            <div class="relative h-full">
                <div [style]="'background: url(' + (block.asset_webp.url ? block.asset_webp.url : block.asset.url) + ')'"
                     class="relative lg:absolute w-full h-[250px] lg:h-[670px] bg-cover rounded-3xl overflow-hidden mb-3 lg:mb-0 z-0 lg:-right-42"></div>

                <div class="grid relative z-1 h-full">
                    <div class="col-12 lg:col-6 flex align-items-center">
                        <app-text [block]="block" [theme]="theme" titleSize="text-4xl"></app-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
