<div [class]="'z-1 container m-auto relative overflow-hidden ' + (container ? 'px-3 md:px-6 lg:px-8 xl:!px-42' : '') + ' ' + (componentOffset)">

    <app-text [block]="block"></app-text>

    <div [class]="'bg-no-repeat px-5 py-5 md:px-6 lg:px-8 relative '
    + (blockClass ? (blockClass + ' ') : '')
    + theme.fullTheme
    + (block.class_overwrite ? (' ' + block.class_overwrite) : '' )"
         [style]="'border-radius:1rem;'">
        <div class="hidden relative mb-3 md:mb-0 md:block md:absolute w-full h-full left-0 top-5 pointer-events-none">
            <img *ngIf="block.asset" priority [height]="block.asset.height" [width]="block.asset.width" [ngSrc]="(block.asset_webp.url ? block.asset_webp.url : block.asset.url)"
                 [class]="'block max-h-full w-auto ml-auto'"
                 alt="">
        </div>

        <div *ngIf="statisticBlock" class="block sm:flex align-items-center relative z-1">
             <img *ngIf="statisticBlock.asset" priority [height]="statisticBlock.asset.height" [width]="statisticBlock.asset.width" [ngSrc]="(block.asset_webp.url ? block.asset_webp.url : block.asset.url)"
                 [class]="'m-auto sm:m-0 block max-w-[200px] h-auto'" alt="">

            <div class="ml-5">
                <p class="mb-3 mt-0 font-bold">{{statisticBlock.title}}</p>
                <div *ngFor="let statistic of statisticBlock.blocks" class="flex align-items-center mb-2">
                    <span [class]="'h-[24px] w-[24px] mr-2 rounded-full bg-brand-lightest inline-block ' + statistic.class_overwrite"></span>
                    <p class="m-0">{{statistic.title}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
