import {Directive, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BlockComponentModel}                        from "../models/block";
import {
    ALIGNMENT,
    BG_VALUES,
    GRID_ALIGNMENT,
    GRID_CONTENT_ALIGNMENT,
    OFFSET,
    returnComponents,
    returnContent,
    Theme,
    TITLE_ALIGNMENT
}                                                   from "../models/util";

@Directive()
export class Block implements OnChanges {
    @Input() block!: BlockComponentModel;
    @Input() theme: Theme       = new Theme('light');
    @Input() blockClass: string = '';
    @Input() container: boolean = true;

    readonly offset                 = OFFSET;
    readonly bg_values              = BG_VALUES;
    readonly alignment              = ALIGNMENT;
    readonly grid_alignment         = GRID_ALIGNMENT;
    readonly grid_content_alignment = GRID_CONTENT_ALIGNMENT;
    readonly title_alignment        = TITLE_ALIGNMENT;

    componentOffset: string = '';

    buttons: BlockComponentModel[]            = [];
    components: BlockComponentModel[]         = [];
    firstButtonBlock?: BlockComponentModel    = undefined;
    firstContentBlock?: BlockComponentModel   = undefined;
    contentComponents?: BlockComponentModel[] = [];
    blockWithoutButtons!: BlockComponentModel;

    protected onChange() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['block'].currentValue) {
            return;
        }

        if (this.block.background_color) {
            this.theme = new Theme(this.block.background_color.value);
        }

        if (this.block.offset && this.block.offset.value !== '0' && this.block.offset.value !== '1') {
            this.componentOffset = `${this.offset[this.block.offset.value][(this.block.offset_alignment.value as 'top' | 'left' | 'bottom' | 'right')]}`;
        } else if (this.block.offset && this.block.offset.value === '1') {
            this.componentOffset = `!mt-0 !mb-0`
        } else {
            this.componentOffset = `my-7`
        }

        this.buttons             = [
            ...returnComponents(this.block, true, 'buttonComponent') as BlockComponentModel[],
            ...returnComponents(this.block, true, 'productButtonComponent') as BlockComponentModel[]
        ];
        this.components          = returnComponents(this.block, true) as BlockComponentModel[];
        this.firstButtonBlock    = returnComponents(this.block, false, 'buttonComponent') as BlockComponentModel;
        this.firstContentBlock   = returnContent(this.block) as BlockComponentModel;
        this.contentComponents   = returnContent(this.block, true) as BlockComponentModel[];
        this.blockWithoutButtons = {
            ...this.block,
            blocks: this.block.blocks.filter(b => b.type !== 'buttonComponent')
        }

        this.onChange();
    }
}
