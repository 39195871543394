import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-pricing-table',
    templateUrl: './pricing-table.component.html',
})
export class PricingTableComponent extends Block {
    public selectedProductType = 0;
}
