import {Component, HostBinding, Input} from '@angular/core';
import {Theme}                         from "../../../models/util";

@Component({
    selector   : 'app-seaweed',
    templateUrl: './seaweed.component.html',
    styleUrl   : './seaweed.component.scss'
})
export class SeaweedComponent {
    @Input() theme: Theme                           = new Theme('dark');
    @Input() width: string                          = '868'
    @Input() height: string                         = '734'
    @Input() alignment: 'left' | 'center' | 'right' = 'left';

    @HostBinding('class') get classes() {
        return {
            'justify-content-end'   : this.alignment === 'right',
            'justify-content-center': this.alignment === 'center',
        }
    }
}
