import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-review',
    templateUrl: './review.component.html',
})
export class ReviewComponent extends Block {

}
