<svg width="124" height="32" viewBox="0 0 124 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1682_3662)">
        <path d="M9.81178 31.9948C8.55519 32.0148 7.30309 31.8407 6.09999 31.4788C5.09444 31.1699 4.14295 30.7078 3.27923 30.1089C2.50657 29.5712 1.82461 28.9145 1.25885 28.1636C0.739968 27.4816 0.31648 26.7326 0 25.9372L5.07944 23.7751C5.48182 24.7651 6.16612 25.616 7.04802 26.2236C7.87029 26.7539 8.83231 27.0285 9.81178 27.0129C10.8479 27.0129 11.6724 26.8255 12.2855 26.4506C12.8942 26.0764 13.1972 25.6275 13.1972 25.1091C13.1997 24.8303 13.1314 24.5556 12.9987 24.3101C12.8661 24.0648 12.6733 23.8569 12.4383 23.7056C11.9306 23.3442 11.0551 23.0193 9.81178 22.7303L6.81489 22.093C6.10192 21.9349 5.40742 21.7032 4.74272 21.4016C4.0444 21.0913 3.3938 20.6838 2.81041 20.1916C2.22652 19.6935 1.74078 19.0916 1.378 18.4166C0.994847 17.6684 0.806895 16.8362 0.831462 15.9966C0.813982 15.0316 1.04589 14.0783 1.50492 13.2283C1.95844 12.4119 2.58945 11.7067 3.35176 11.1644C4.18795 10.5777 5.12066 10.1412 6.10776 9.87439C7.2158 9.56276 8.36257 9.40902 9.51392 9.41776C11.3957 9.383 13.2592 9.79047 14.9534 10.6071C16.5383 11.4094 17.7784 12.7555 18.445 14.397L13.5391 16.3862C13.1742 15.5968 12.5452 14.9584 11.7596 14.5802C11.0351 14.2201 10.2381 14.0279 9.42843 14.0178C8.69119 13.9981 7.96106 14.1659 7.30703 14.5054C6.69747 14.8356 6.39354 15.2605 6.39527 15.7799C6.39655 16.0252 6.46722 16.2652 6.59916 16.4724C6.73109 16.6796 6.91897 16.8455 7.14127 16.9512C7.79676 17.2945 8.49293 17.5545 9.21344 17.7252L12.4694 18.4992C14.64 19.0151 16.2537 19.8442 17.3105 20.9862C18.3469 22.0768 18.9147 23.5276 18.8932 25.029C18.8947 25.9474 18.6715 26.8524 18.243 27.6657C17.7883 28.5262 17.1605 29.2841 16.3987 29.8922C15.5424 30.5755 14.568 31.0973 13.5236 31.4325C12.3253 31.82 11.0715 32.0099 9.81178 31.9948Z"
              fill="#1F1F1F"/>
        <path d="M24.4852 14.9583H20.75V10.1157H24.4852V3.62976H30.1837V10.1157H35.3926V14.9583H30.1707V23.4232C30.166 23.8953 30.2174 24.3664 30.3235 24.8267C30.4133 25.2381 30.6161 25.6168 30.909 25.9204C31.1255 26.1619 31.3941 26.3515 31.6948 26.4749C31.9952 26.5983 32.32 26.6521 32.6444 26.6325C33.3546 26.674 34.0581 26.4758 34.6415 26.0702L36.2473 31.0418C35.5631 31.365 34.8422 31.6048 34.1001 31.7563C33.2633 31.9208 32.412 32.0003 31.5591 31.9937C30.5731 32.0061 29.5937 31.8309 28.6736 31.4777C27.8665 31.1704 27.1337 30.6966 26.5237 30.087C25.1647 28.7645 24.4852 26.882 24.4852 24.4395V14.9583Z"
              fill="#1F1F1F"/>
        <path d="M39.5479 10.1162H44.8862V13.0548H45.2334C45.5003 12.5274 45.8502 12.0457 46.2693 11.6281C46.7092 11.1952 47.1996 10.8167 47.7303 10.5006C48.2764 10.1761 48.8589 9.91633 49.4657 9.72664C50.0621 9.53253 50.6851 9.43242 51.3127 9.42995C51.9641 9.41691 52.614 9.49771 53.2423 9.66988C53.7276 9.80695 54.189 10.0175 54.61 10.2942L53.092 15.3535C52.7258 15.1728 52.3399 15.0343 51.9421 14.9407C51.4357 14.832 50.9186 14.7818 50.4009 14.7911C49.6663 14.7806 48.9392 14.9368 48.2742 15.2477C47.64 15.5492 47.083 15.9903 46.645 16.5377C46.1842 17.1144 45.833 17.7702 45.6089 18.4727C45.3562 19.2508 45.2311 20.0644 45.2385 20.8824V31.3157H39.5479V10.1162Z"
              fill="#1F1F1F"/>
        <path d="M63.2562 31.3031V10.1166H57.5576V31.3031H63.2562Z" fill="#1F1F1F"/>
        <path d="M74.4925 31.3031V10.1166H68.7939V31.3031H74.4925Z" fill="#1F1F1F"/>
        <path d="M77.4434 10.1166H83.9188L89.2159 23.6072H89.5215L94.8574 10.1166H101.281L92.1222 31.3031H86.5169L77.4434 10.1166Z"
              fill="#1F1F1F"/>
        <path d="M123.714 20.2322C123.673 19.1509 123.532 18.0756 123.292 17.0202C123.292 16.9995 123.277 16.9763 123.274 16.9583C123.199 16.6771 123.116 16.4036 123.015 16.1327C122.584 14.831 121.889 13.6315 120.974 12.6085C120.052 11.5938 118.918 10.7939 117.651 10.2659C116.203 9.68344 114.653 9.39739 113.092 9.42484C111.561 9.40705 110.044 9.71675 108.644 10.333C105.987 11.4997 103.903 13.6691 102.85 16.3649C102.297 17.7467 102.017 19.2219 102.026 20.7094C102.008 22.2557 102.294 23.7905 102.868 25.227C103.408 26.5653 104.214 27.7809 105.238 28.8007C106.262 29.8204 107.482 30.6233 108.826 31.1608C110.253 31.7307 111.779 32.0155 113.317 31.9993C115.721 31.9993 117.754 31.4679 119.417 30.405C121.066 29.351 122.43 27.9094 123.388 26.2073L118.658 23.8855C118.17 24.7756 117.479 25.5391 116.64 26.1145C115.786 26.7062 114.707 27.0012 113.405 26.9995C112.744 27.0012 112.086 26.9074 111.452 26.7207C110.814 26.5302 110.217 26.2222 109.693 25.8127C109.148 25.3831 108.691 24.8532 108.346 24.2519C107.963 23.565 107.721 22.809 107.634 22.028H123.649C123.699 21.7179 123.728 21.4051 123.738 21.0913V20.5548C123.719 20.4334 123.719 20.3328 123.714 20.2322ZM107.914 17.9851C108.23 16.8304 108.921 15.8125 109.88 15.0904C110.804 14.3995 111.934 14.0363 113.089 14.0584C113.85 14.0392 114.608 14.168 115.319 14.4377C115.894 14.6724 116.421 15.01 116.873 15.4335C117.267 15.8035 117.592 16.2403 117.832 16.7235C118.026 17.1174 118.15 17.5415 118.2 17.9774L107.914 17.9851Z"
              fill="#1F1F1F"/>
        <path d="M60.3841 7.26333C59.4139 7.26475 58.4827 6.88351 57.7939 6.20297C57.4642 5.87369 57.2005 5.48493 57.0168 5.05748C56.6402 4.14331 56.6402 3.11824 57.0168 2.20407C57.2005 1.77583 57.4642 1.38624 57.7939 1.05599C58.4855 0.380328 59.4154 0.00183105 60.3841 0.00183105C61.3527 0.00183105 62.2828 0.380328 62.9744 1.05599C63.3189 1.39229 63.5927 1.79369 63.7796 2.2367C63.9664 2.67971 64.0627 3.15541 64.0627 3.63593C64.0627 4.11647 63.9664 4.59216 63.7796 5.03516C63.5927 5.47817 63.3189 5.87957 62.9744 6.21588C62.634 6.55824 62.2278 6.82863 61.78 7.01082C61.3322 7.19303 60.8522 7.2833 60.3686 7.27624L60.3841 7.26333Z"
              fill="#0DBB52"/>
        <path d="M71.6272 7.26344C70.6568 7.26598 69.7251 6.88457 69.0371 6.20308C68.7074 5.87378 68.4437 5.48504 68.26 5.05759C67.8834 4.14342 67.8834 3.11832 68.26 2.20415C68.4437 1.77593 68.7074 1.38634 69.0371 1.05609C69.373 0.724525 69.7707 0.461596 70.2077 0.282102C70.8793 0.00431865 71.6182 -0.070414 72.3322 0.0672215C73.0462 0.204857 73.7036 0.548772 74.2226 1.05609C74.5672 1.39238 74.8411 1.79379 75.028 2.2368C75.2149 2.67981 75.3111 3.15551 75.3111 3.63603C75.3111 4.11655 75.2149 4.59225 75.028 5.03526C74.8411 5.47827 74.5672 5.87967 74.2226 6.21598C73.883 6.55849 73.4774 6.82901 73.03 7.01124C72.5825 7.19347 72.1027 7.28365 71.6195 7.27632L71.6272 7.26344Z"
              fill="#0DBB52"/>
    </g>
    <defs>
        <clipPath id="clip0_1682_3662">
            <rect width="124" height="32" fill="white"/>
        </clipPath>
    </defs>
</svg>
