import {Component, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {BlockComponentModel}                                                         from "../../../models/block";
import {returnComponents}                                                            from "../../../models/util";
import {SEO}                                                                         from "../../../models/generic";
import {TranslateService}                                                            from "@ngx-translate/core";
import {MenuItem}                                                                    from "primeng/api";

@Component({
    selector   : 'app-menu',
    templateUrl: './menu.component.html',
    styleUrl   : './menu.component.scss',
})
export class MenuComponent implements OnChanges, OnInit {
    @Input() menu!: BlockComponentModel[];
    @Input() seo: SEO | undefined = undefined;

    buttons: BlockComponentModel[]      = [];
    menuItems: MenuItem[]               = [];
    currentLang: string                 = 'nl';
    menuItemsNew: BlockComponentModel[] = [];

    protected readonly locales: string[] = ['nl', 'en'];

    links: { [label: string]: string } = {};

    private innerWidth: number                   = 0;
    private menuOpen: boolean                    = false;
    rootItemTitles: { [value: string]: boolean } = {};

    constructor(
        private renderer: Renderer2,
        private readonly translateService: TranslateService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['menu'].currentValue) {
            return;
        }

        this.buttons      = returnComponents({blocks: this.menu}, true, 'menuButton') as BlockComponentModel[];
        this.menuItems    = (returnComponents({blocks: this.menu}, true, 'menuItem') as BlockComponentModel[]).map(c => {
            const f: (block: BlockComponentModel) => MenuItem = (block: BlockComponentModel) => {
                return <MenuItem>{
                    url   : block.entry ? block.entry : (block.url ? block.url : ''),
                    icon  : block.icon.value !== 'noIcon' ? ('pi ' + block.icon.value) : '',
                    label : block.title,
                    items : block.blocks.map(i => f(i)),
                    target: '_self',
                }
            }

            return f(c);
        });
        this.menuItemsNew = (returnComponents({blocks: this.menu}, true, 'menuItem') as BlockComponentModel[]).map(c => {
            const f: (block: BlockComponentModel) => BlockComponentModel = (block: BlockComponentModel) => {
                return {
                    ...block,
                    url   : block.entry ? block.entry : (block.url ? block.url : ''),
                    blocks: block.blocks.map(i => f(i)),
                }
            }

            return f(c);
        });

        if (this.seo) {
            this.links = this.seo.canonical.reduce((r, i) => {
                r[i.locale] = i.uri;
                return r
            }, <{ [label: string]: string }>{})
        }

        this.currentLang = this.translateService.currentLang;
    }

    ngOnInit() {
        this.onResize();
    }

    @HostListener('window:resize', [])
    onResize() {
        if (typeof window !== "undefined") {
            this.innerWidth = window.innerWidth;

            if (this.innerWidth >= 1024) {
                this.rootItemTitles = {};
                this.menuOpen       = false;
                this.renderer.removeClass(document.body, 'overflow-hidden');
            }
        }
    }

    protected changeLanguage(locale: string): void {
        localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE', locale);
    }

    mobileOpen(event: any, title: string) {
        if (this.innerWidth < 1024 && this.rootItemTitles[title]) {
            event.preventDefault();
            delete this.rootItemTitles[title];
        } else if (this.innerWidth < 1024) {
            event.preventDefault();
            this.rootItemTitles[title] = true;
        }
    }

    openMenu() {
        if (!document) {
            return;
        }

        if (this.menuOpen) {
            this.menuOpen = false;
            this.renderer.removeClass(document.body, 'overflow-hidden');
            return;
        }

        this.menuOpen = true;
        this.renderer.addClass(document.body, 'overflow-hidden');
    }
}
