import {Component, OnInit} from '@angular/core';
import {Block}             from "../../block";
import {Router}            from "@angular/router";

@Component({
    selector   : 'app-vertical-tabs',
    templateUrl: './vertical-tabs.component.html',
})
export class VerticalTabsComponent extends Block implements OnInit {
    activeTab = 0;

    constructor(private readonly router: Router) {
        super();
    }

    ngOnInit() {
        const [url, segment]  = this.router.url.split('#');
        const [identifier, _] = segment.split('?');

        const activeTab = this.block.blocks.findIndex(b => {
            const title = b.title
                .toLowerCase()
                .replace(/\s/g, "");
            let doc     = new DOMParser().parseFromString(title, 'text/html');
            return identifier === doc.body.textContent
        });

        if (activeTab >= 0) {
            this.activeTab = activeTab
        }
    }
}
