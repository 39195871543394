<app-menu *ngIf="header && page" [menu]="header.blocks" [seo]="page.seo"></app-menu>
<app-breadcrumbs *ngIf="breadcrumbs" [block]="breadcrumbs" [ancestors]="ancestors"></app-breadcrumbs>
<app-banner *ngIf="banner" [block]="banner"></app-banner>


<ng-container *ngIf="page">
    <div [style]="'background: linear-gradient(180deg, rgba(20, 55, 40, 0) 24%, rgba(20, 55, 40, 0.4) 57%), url(' + page.image + ')'" class="text-center py-42 bg-cover bg-no-repeat">

        <div class="flex justify-content-center">
            <p-tag class="block uppercase" severity="success"
                   *ngFor="let category of page.categories; let last = last;"
                   [ngClass]="{'mr-3': !last}">{{ category.title }}
            </p-tag>
        </div>
        <h1 class="text-white text-5xl">{{ page.title }}</h1>
    </div>

    <div class="container m-auto">
        <div class="relative py-8 md:px-6 lg:px-8 xl:!px-42 -mt-42">
            <div class="bg-white p-0 py-1 md:px-8 rounded-3xl">
                <app-block-generator [container]="false" [page]="page"></app-block-generator>
            </div>
        </div>
    </div>
</ng-container>

<app-footer *ngIf="footer" [menu]="footer.blocks"></app-footer>
