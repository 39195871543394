import {Component, Input}    from '@angular/core';
import {BlockComponentModel} from "../../../../models/block";

@Component({
    selector   : 'app-blog-grid-item',
    templateUrl: './blog-grid-item.component.html',
})
export class BlogGridItemComponent {
    @Input() block!: BlockComponentModel;
}
