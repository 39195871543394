import {ApplicationConfig, importProvidersFrom}       from '@angular/core';
import {provideRouter, withDisabledInitialNavigation} from '@angular/router';

import {routes}                                             from './app.routes';
import {
    provideClientHydration
}                                                           from '@angular/platform-browser';
import {
    provideAnimationsAsync
}                                                           from "@angular/platform-browser/animations/async";
import {HttpClient, provideHttpClient, withFetch}           from "@angular/common/http";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {
    translateLoaderFactory
}                                                           from "./translate-loader-factory";
import {
    LocalizeParser,
    LocalizeRouterModule,
    LocalizeRouterSettings,
    ManualParserLoader
}                                                           from "@gilsdav/ngx-translate-router";
import {Location}                                           from "@angular/common"

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withDisabledInitialNavigation()),
        provideHttpClient(withFetch()),
        importProvidersFrom(
            TranslateModule.forRoot({
                defaultLanguage: 'nl',
                loader         : {
                    deps      : [HttpClient],
                    provide   : TranslateLoader,
                    useFactory: translateLoaderFactory,
                },
            }),
            LocalizeRouterModule.forRoot(routes, {
                parser           : {
                    deps      : [TranslateService, Location, LocalizeRouterSettings, HttpClient],
                    provide   : LocalizeParser,
                    useFactory: (translate: any, location: any, settings: any) => new ManualParserLoader(translate, location, settings, ['nl', 'en']),
                },
                initialNavigation: true,
            }),
        ),

        provideAnimationsAsync(),
        provideClientHydration(),
    ]
};
