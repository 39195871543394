import {Component} from '@angular/core';
import {Block}     from "../../../block";

@Component({
    selector   : 'app-search-box-header',
    templateUrl: './search-box-header.component.html',
})
export class SearchBoxHeaderComponent extends Block {

}
