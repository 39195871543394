import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-logos',
    templateUrl: './logos.component.html',
    styleUrl   : './logos.component.scss'
})
export class LogosComponent extends Block {

}
