import {Component, Input} from '@angular/core';
import {Block}            from "../../block";

@Component({
    selector   : 'app-text',
    templateUrl: './text.component.html',
})
export class TextComponent extends Block {
    @Input() titleSize: 'text-2xl' | 'text-3xl' | 'text-4xl' = 'text-3xl';
    @Input() isHeading                          = false;
}
