import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {Block}                                                      from "../../block";

@Component({
    selector   : 'app-cookie-declaration',
    templateUrl: './cookie-declaration.component.html',
})
export class CookieDeclarationComponent extends Block implements AfterViewInit {

    @ViewChild('cookieScriptSection') cookieScriptSection!: ElementRef;

    constructor(
        private _renderer2: Renderer2,
    ) {
        super();
    }

    ngAfterViewInit() {
        const foundScript: any[] = this.cookieScriptSection.nativeElement?.getElementsByTagName('script');
        if (foundScript.length === 0) {

            let script  = this._renderer2.createElement('script');
            script.id = 'CookieDeclaration'
            script.type = 'text/javascript'
            script.src = `https://consent.cookiebot.com/${this.block.text}/cd.js`

            this._renderer2.appendChild(this.cookieScriptSection.nativeElement, script);
        }
    }
}
