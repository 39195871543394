<div class="rounded-3xl overflow-hidden flex flex-column bg-primary-800 text-white p-5 flex-grow-1" style="max-width: 370px">
    <div class="font-medium text-xl mb-2">{{block.title}}</div>
    <div class="mt-2 mb-2" [innerHTML]="block.content_limited | safeHTML"></div>
    <div *ngIf="block.text" class="text-sm"><i class="pi pi-info-circle text-primary-lighter mr-2"></i><span>{{block.text}}</span></div>

    <hr class="my-3 mx-0 border-top-1 border-brand-color border-none"/>
    <ul class="list-none p-0 m-0 flex-grow-1">
        <ng-container *ngFor="let feature of components">
            <li *ngIf="feature.type === 'feature' && ((feature.icon && feature.icon.value != 'no_icon') || feature.content_limited)" class="flex align-items-center mb-3" >
                <i class="pi pi-check text-green-500 mr-2"></i>
                <span>{{feature.title}}</span>
            </li>
        </ng-container>
    </ul>

    <app-button *ngIf="buttons[0]" [block]="buttons[0]" blockClass='w-full justify-around mr-0 md:mr-3'></app-button>
</div>
