<ng-container *ngIf="page">

    <app-menu *ngIf="header" [menu]="header.blocks" [seo]="page.seo"></app-menu>
    <app-banner *ngIf="banner" [block]="banner"></app-banner>

    <div class="surface-card overflow-hidden">
        <div class="container m-auto relative">
            <app-seaweed alignment="left"></app-seaweed>

            <div class="relative pt-7 pb-42 md:px-6 lg:px-8 xl:!px-42">
                <h1 class="text-5xl text-center text-white" [innerHTML]="page.title | safeHTML"></h1>

                <div class="flex justify-content-center">
                    <span class="inline text-sm text-white py-2.5 px-3 border-1 border-primary rounded-3xl font-bold mr-2 bg-primary cursor-pointer"
                          (click)="navigateTo()"
                          [ngClass]="{'bg-primary': !currentCategory}">Alles</span>
                    <span class="inline text-sm text-white py-2.5 px-3 border-1 border-primary rounded-3xl font-bold cursor-pointer"
                          *ngFor="let category of categories; let last = last"
                          (click)="navigateTo(category)"
                          [ngClass]="{'mr-2': !last, 'bg-primary': currentCategory === category.slug}">{{ category.title }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="container mx-auto -mt-42">
        <div class="relative py-7 md:px-6 lg:px-8 xl:!px-42">
            <div class="grid">
                <div class="col-12 lg:col-6 xl:col-4" *ngFor="let blog of page.blocks">
                    <app-blog-grid-item [block]="blog"></app-blog-grid-item>
                </div>
            </div>
        </div>
    </div>

    <app-footer *ngIf="footer" [menu]="footer.blocks"></app-footer>
</ng-container>
