import {Routes}                from '@angular/router';
import {PageComponent}         from "./pages/page/page.component";
import {BlogComponent}         from "./pages/blog/blog.component";
import {BlogOverviewComponent} from "./pages/blog/blog-overview/blog-overview.component";
import {BlogItemComponent}     from "./pages/blog/blog-item/blog-item.component";
import {AssignmentsComponent}  from "./pages/assignments/assignments.component";

export const routes: Routes = [
    {
        path     : 'blog',
        data     : {
            discriminantPathKey: 'BLOGPATH'
        },
        component: BlogComponent,
        children : [
            {
                path     : '',
                data     : {revalidate: 5},
                component: BlogOverviewComponent,
            },
            {
                path     : '**',
                data     : {revalidate: 5},
                component: BlogItemComponent,
            },
        ]
    },
    {
        path     : 'opdrachten',
        data     : {
            revalidate         : 5,
            discriminantPathKey: 'ASSIGNMENTSPATH',
        },
        component: AssignmentsComponent,
    },
    {
        path     : '',
        data     : {
            revalidate         : 5,
            discriminantPathKey: 'ROUTEPATH',
        },
        component: PageComponent,
    },
    {
        path     : '**',
        data     : {
            revalidate         : 5,
            discriminantPathKey: 'ROUTEPATH',
        },
        component: PageComponent,
    },
];
