<div [class]="(blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' )">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <div class="flex mb-5 flex-column justify-center gap-5 pb-5">
                <div class="text-white self-center">
                    <div *ngIf="block.title"
                         [class]="'font-bold text-3xl'"
                         [innerHTML]="block.title | safeHTML"></div>
                </div>
                <div *ngIf="block.blocks.length > 1" class="flex md:ml-5 self-center">
                    <ul class="surface-0 p-2 m-0 list-none flex column-gap-2 overflow-x-auto select-none surface-border"
                        style="border-radius: 35px">
                        <li *ngFor="let productType of block.blocks; let index = index">
                            <button pRipple
                                    class="p-link cursor-pointer px-4 py-3 flex align-items-center transition-color transition-duration-150"
                                    [ngClass]="{
                                        'bg-primary': selectedProductType == index,
                                        'text-600': selectedProductType != index,
                                        'hover:surface-hover': selectedProductType != index}"
                                    (click)="selectedProductType = index" style="border-radius: 30px">
                                <span class="font-medium">{{ productType.title }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div>
                <ng-container *ngFor="let productType of block.blocks; let index = index">
                    <div *ngIf="index == selectedProductType">
                        <div class="w-full flex-column align-items-start flex md:flex-row justify-content-center gap-5">
                            <ng-container *ngFor="let product of productType.blocks">
                                <app-pricing-header-column [block]="product"></app-pricing-header-column>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
