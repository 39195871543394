import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-title',
    templateUrl: './title.component.html',
})
export class TitleComponent extends Block {

}
