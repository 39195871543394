import {Component, Input, OnInit} from '@angular/core';
import {BlockComponentModel}      from "../../../models/block";

@Component({
    selector   : 'app-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
    @Input() menu!: BlockComponentModel[];

    year!: number;

    ngOnInit(): void {
        this.year = new Date().getFullYear();
    }
}
