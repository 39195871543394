<div [class]="'z-1 relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '') + ' ' + (componentOffset) + ' ' + (theme.theme === 'dark' && block.fullscreen ? theme.fullTheme : '')">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' )"
             [ngClass]="{'py-7 rounded-3xl': theme.theme === 'dark', 'px-5': theme.theme === 'dark' && !block.fullscreen}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <ul class="list-none flex flex-column gap-3 lg:flex-row justify-content-between p-0 relative">
                <div class="w-[2px] block lg:hidden absolute left-[28px] bg-brand-color h-[calc(100%-56px)]"></div>

                <li *ngFor="let step of block.blocks; let last = last; let first = first;"
                    class="flex align-items-start lg:align-items-center flex-row lg:flex-column gap-3 relative w-full">
                    <div *ngIf="last" class="w-full hidden lg:block absolute right-50 bg-brand-color"
                         style="transform: translateY(-50%); height: 2px; top: 28px"></div>

                    <div class="flex justify-content-center align-items-center border-circle bg-brand-color w-14 h-14 z-1 flex-shrink-0">
                        <app-icon [icon]="step.icon.value" class="text-900"></app-icon>
                    </div>

                    <div *ngIf="first" class="w-full hidden lg:block absolute left-50 bg-brand-color"
                         style="transform: translateY(-50%); height: 2px; top: 28px"></div>
                    <ng-container *ngIf="!first && !last">
                        <div class="w-full hidden lg:block absolute left-50 bg-brand-color"
                             style="transform: translateY(-50%); height: 2px; top: 28px"></div>
                    </ng-container>

                    <div class="flex flex-column align-items-start lg:align-items-center">
                        <div [innerHTML]="step.title | safeHTML"
                             class="font-bold line-height-3 mb-1 [&>p]:mb-0 [&>p]:mt-0 lg:text-center"></div>
                        <div *ngIf="step.content_limited" [innerHTML]="step.content_limited | safeHTML"
                             class="line-height-3 [&>p]:mb-0 [&>p]:mt-0 text-left lg:text-center"></div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
