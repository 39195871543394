import {AfterViewInit, Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {HttpClient}                                                    from "@angular/common/http";
import {of, Subject, switchMap}                                        from "rxjs";
import {EMPTY_STATE}                                                   from "./empty-state";
import {Block}                                                         from "../../block";

@Component({
    selector   : 'app-tool',
    templateUrl: './tool.component.html',
})
export class ToolComponent extends Block implements AfterViewInit {
    @ViewChild('containerRef', {static: true}) containerRef!: ElementRef<HTMLDivElement>;

    view: [number, number] = [0, 130];
    colorScheme: any       = {
        domain: ['#A0A0A0']
    };

    barWidth       = 0;
    totalBars      = 0;
    averageBarLeft = 0;

    ngModel = null;

    query                  = '';
    minCost: null | number = null;
    maxCost: null | number = null;

    result: {
        average: number,
        buckets: { [key: string | number]: number }
    }                                                            = EMPTY_STATE;
    results: { name: string | number, value: string | number }[] = [];
    averageCost: null | number                                   = null;
    professionalLength: null | number                            = null;

    typeahead$   = new Subject<string>();
    completions$ = this.typeahead$.pipe(
        switchMap(term => {
            if (!term) {
                return of([]);
            }

            return this.http.get<any>(`https://cms.striive.com/benchmark.php?q=${term}`);
        })
    )

    constructor(
        private readonly http: HttpClient,
    ) {
        super();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.resizeChart(this.containerRef.nativeElement.offsetWidth);
        this.calculateBarPositioning(this.result);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.resizeChart(this.containerRef.nativeElement.offsetWidth);

            this.result  = EMPTY_STATE;
            this.results = this.mapChart(EMPTY_STATE.buckets);
            this.calculateBarPositioning(EMPTY_STATE);
        });
    }

    getResults(name: null | string) {
        if (!name) {
            return;
        }

        this.query   = name
        this.ngModel = null;
        this.http.get<{
            average: number,
            buckets: { [key: string | number]: number }
        }>(`https://cms.striive.com/benchmark.php?name=${name}`).subscribe(result => {
            this.result             = result;
            this.results            = this.mapChart(result.buckets);
            this.minCost            = Math.min(...Object.keys(result.buckets).map(k => Number(k)));
            this.maxCost            = Math.max(...Object.keys(result.buckets).map(k => Number(k)));
            this.averageCost        = Math.round(result.average);
            this.professionalLength = Object.values(result.buckets).reduce((r, p) => r += p, 0);

            setTimeout(() => {
                this.resizeChart(this.containerRef.nativeElement.offsetWidth);
                this.calculateBarPositioning(result);
            })
        });
    }

    resizeChart(width: number): void {
        if (typeof window !== "undefined") {
            let height = 130;
            if (window.innerWidth <= 768) {
                height = 110;
            }

            this.view = [width, height]
        }
    }

    private mapChart(data: { [key: string | number]: number }) {
        return Object.keys(data)
            .map(key => ({name: key, value: data[key] <= 3 ? 3 : data[key]}))
    }

    private calculateBarPositioning(result: {
        average: number,
        buckets: { [key: string | number]: number }
    }) {
        const totalBars        = Object.keys(result.buckets).length;
        const closestToAverage = Object.keys(result.buckets).reduce((prev: any, curr: any) => {
            return (Math.abs(curr - result.average) < Math.abs(prev - result.average) ? curr : prev);
        });
        const averageBarIndex  = Object.keys(result.buckets).findIndex(key => key === closestToAverage);

        // (this.totalBars - 2) * 2: don't count first and last bar, remove 2px spacing width for each bar.
        // + 48 is padding left + right.
        this.barWidth       = Math.floor(((this.containerRef.nativeElement.offsetWidth - (((totalBars - 2) * 2) + 48)) / totalBars));
        this.totalBars      = totalBars;
        this.averageBarLeft = ((averageBarIndex + 1) / totalBars * 100);
    }
}
