export const EMPTY_STATE = {
  "buckets"   : {
    "110": 4,
    "45" : 5,
    "185": 3,
    "150": 5,
    "75" : 2,
    "65" : 7,
    "80" : 1,
    "85" : 2,
    "90" : 7,
    "115": 1,
    "105": 3
  }, "average": 97.80125000000002
}

