import {Component}             from '@angular/core';
import {Block}                 from "../../block";
import {Router}                from "@angular/router";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {TranslateService}      from "@ngx-translate/core";

@Component({
    selector   : 'app-jobs',
    templateUrl: './jobs.component.html',
})
export class JobsComponent extends Block {
    constructor(
        private readonly router: Router,
        private readonly localize: LocalizeRouterService,
        private readonly translate: TranslateService
    ) {
        super();
    }

    navigateJob(id: string) {
        const currentLang = this.translate.currentLang;
        this.router.navigate([this.localize.translateRoute(`${(currentLang + '/')}opdrachten`)], {
            queryParams: {
                id
            }
        });
    }

}
