import {Component, Input} from '@angular/core';
import {Block}            from "../../block";
import {Ancestor}         from "../../../models/util";

@Component({
    selector   : 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent extends Block {
    @Input() ancestors: Ancestor[] = [];
}
