<div class="bg-brand-lightest shadow-2 relative lg:!static">
    <div class="container-fluid m-auto py-3 flex align-items-center justify-content-between px-3 md:!px-16 lg:!px-6 xl:!px-12 2xl:!px-29">
        <a [href]="('/' + currentLang)" aria-label="Home">
            <app-logo></app-logo>
        </a>

        <div class="flex w-full align-items-center lg:!justify-content-between flex-row-reverse lg:!flex-row">
            <div class="lg:!w-full z-5">
                <div class="cursor-pointer ml-3 flex lg:!hidden text-700 justify-content-end align-items-center"
                     (click)="openMenu()"
                     pStyleClass="@next" enterClass="hidden"
                     leaveToClass="hidden" [hideOnOutsideClick]="true">
                    <i class="pi pi-bars text-2xl"></i>
                </div>

                <div class="align-items-center pt-2.5 flex-grow-1 justify-content-between hidden bg-brand-lightest overflow-scroll lg:!pt-0 lg:!overflow-visible h-screen lg:!h-auto lg:!bg-transparent lg:!flex absolute lg:!static w-full left-0 top-100 z-1 shadow-2 lg:!shadow-none">
                    <ul class="list-none px-3 md:!px-16 lg:px-0 py-0 m-0 flex lg:!align-items-center select-none flex-column lg:!flex-row lg:!flex-wrap lg:!w-full lg:!justify-content-center">
                        <li class="lg:!py-0 lg:!relative group lg:!self-end"
                            *ngFor="let menuItem of menuItemsNew; let last = last"
                            [ngClass]="{'lg:!mr-5': !last}">
                            <a
                                    (click)="mobileOpen($event, menuItem.title)"
                                    [target]="menuItem.target"
                                    [href]="menuItem.url"
                                    [ngClass]="{'bg-orange-500': rootItemTitles[menuItem.title]}"
                                    class="no-underline text-900 font-bold py-2.5 px-2.5 lg:!px-0 lg:!py-1.5 border-round cursor-pointer transition-colors transition-duration-150 text-sm block">
                                <span *ngIf="menuItem.subtitle"
                                      class="text-700 uppercase bold text-[10px] hidden lg:!block absolute -top-[10px]">{{ menuItem.subtitle }}</span>

                                <div class="flex align-items-center">
                                    <span><span
                                            class="inline-block lg:!hidden">{{ menuItem.subtitle }}&nbsp;</span>{{ menuItem.title }}</span>
                                    <i class="pi pi-angle-down ml-auto lg:!ml-1"></i>
                                </div>
                            </a>

                            <ul [ngClass]="{'!block': rootItemTitles[menuItem.title]}"
                                class="lg:group-hover:!block list-none hidden m-0 px-0 py-0 border-round shadow-0 lg:!shadow-2 lg:!border-1 border-50 lg:!absolute lg:!bg-white origin-top w-full lg:!w-[240px] cursor-pointer z-5">
                                <li class="block lg:!hidden">
                                    <a pRipple
                                       [target]="menuItem.target"
                                       [href]="menuItem.url"
                                       class="flex pl-5 pr-2.5 py-2.5 lg:!pr-3 lg:!pl-3 lg:!py-3 no-underline align-items-center text-900 hover:surface-100 transition-colors transition-duration-150 text-sm">
                                        <app-icon [icon]="menuItem.icon.value" class="mr-2"></app-icon>
                                        <span class="font-medium">{{ menuItem.title }}</span>
                                    </a>
                                </li>
                                <ng-container *ngIf="menuItem.blocks.length > 0">
                                    <li *ngFor="let subMenuItem of menuItem.blocks">
                                        <a pRipple
                                           [target]="subMenuItem.target"
                                           [href]="subMenuItem.url"
                                           class="flex pl-5 pr-2.5 py-2.5 lg:!pr-3 lg:!pl-3 lg:!py-3 no-underline align-items-center text-900 hover:surface-100 transition-colors transition-duration-150 text-sm">
                                            <app-icon [icon]="subMenuItem.icon.value" class="mr-2"></app-icon>
                                            <span class="font-medium">{{ subMenuItem.title }}</span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>

                        <div class="border-top-1 pt-3 mt-3 surface-border block shrink-0 md:hidden">
                            <app-button *ngFor="let button of buttons; let last = last" [block]="button"
                                        blockClass="mb-3"></app-button>

                            <div class="relative">
                                <div
                                        pRipple
                                        class="no-underline text-700 cursor-pointer flex px-0 align-items-center transition-colors transition-duration-150 justify-content-center lg:!justify-content-start"
                                        pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein"
                                        leaveToClass="hidden"
                                        leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                                    <app-icon icon="pi-globe" class="mr-2"></app-icon>
                                    {{ 'LANGUAGE' | translate }}
                                </div>
                                <ul class="list-none p-0 m-0 mt-3 border-round shadow-0 lg:!shadow-2 lg:!absolute surface-overlay hidden origin-top w-full lg:!w-[56px] z-5">
                                    <li *ngFor="let locale of locales">
                                        <a pRipple
                                           (click)="changeLanguage(locale)"
                                           [href]="links[locale]"
                                           class="no-underline uppercase text-900 font-bold flex p-3 align-items-center hover:surface-50 transition-colors transition-duration-150 border-round">
                                            {{ locale }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>

            <div class="align-items-center justify-content-between surface-border hidden md:flex shrink-0">
                <app-button *ngFor="let button of buttons; let last = last" [block]="button"
                            [blockClass]="!last ? 'mr-0 md:mr-2' : ''"></app-button>

                <div class="ml-3 relative">
                    <div
                            pRipple
                            class="no-underline text-700 cursor-pointer flex px-0 align-items-center transition-colors transition-duration-150"
                            pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                            leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                        <app-icon icon="pi-globe" class="mr-2"></app-icon>
                        {{ 'LANGUAGE' | translate }}
                    </div>
                    <ul class="list-none p-0 m-0 mt-3 border-round shadow-0 lg:!shadow-2 lg:!absolute surface-overlay hidden origin-top w-full lg:!w-[56px] z-5">
                        <li *ngFor="let locale of locales">
                            <a pRipple
                               (click)="changeLanguage(locale)"
                               [href]="links[locale]"
                               class="no-underline uppercase text-900 font-bold flex p-3 align-items-center hover:surface-50 transition-colors transition-duration-150 border-round">
                                {{ locale }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>
