import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {Block}                               from "../../block";

@Component({
    selector   : 'app-google-map',
    templateUrl: './google-map.component.html',
})
export class GoogleMapComponent extends Block implements OnChanges {
    latLng                          = {lat: 0, lng: 0};
    options: google.maps.MapOptions = {
        zoom             : 16,
        zoomControl      : false,
        mapTypeControl   : false,
        fullscreenControl: false,
        streetViewControl: false,
    };

    override ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if (!changes['block'].currentValue) {
            return;
        }

        const [lat, lng]    = this.block.googleMapAddress.split(',');
        this.latLng         = {lat: Number(lat), lng: Number(lng)}
        this.options.center = this.latLng;
    }
}
