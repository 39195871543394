import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-grid-content',
    templateUrl: './grid-content.component.html',
    styles     : `
      app-content {
        width: 100%;
      }
    `
})
export class GridContentComponent extends Block {
}
