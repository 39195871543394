<div class="flex flex-column h-full">
    <div>
        <app-menu *ngIf="header && page" [menu]="header.blocks" [seo]="page.seo"></app-menu>
        <app-breadcrumbs *ngIf="breadcrumbs" [block]="breadcrumbs" [ancestors]="ancestors"></app-breadcrumbs>
        <app-banner *ngIf="banner" [block]="banner"></app-banner>
    </div>

    <div class="flex-grow-1">
        <app-block-generator *ngIf="!notFound; else notFoundTemplate" [page]="page"></app-block-generator>
        <ng-template #notFoundTemplate>
            <div class="z-1 relative h-full flex align-items-center">
                <div class="container m-auto relative overflow-hidden px-3 lg:px-0 py-16">
                    <app-seaweed alignment="left" [theme]="notFoundTheme"></app-seaweed>

                    <div class="relative flex align-items-center justify-content-center flex-col z-1">
                        <img src="/assets/development/images/not_found.png" alt="Not found" class="max-w-[256px] mb-5">
                        <h1 class="mb-5 mt-0">{{'NOT_FOUND' | translate}}</h1>
                        <a pButton pRipple href="/"
                           [class]="'font-bold no-underline block md:inline-block rounded-3xl'">
                            {{'BACK_TO_HOME' | translate}}
                        </a>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <app-footer *ngIf="footer" [menu]="footer.blocks"></app-footer>
</div>
