import {Component}    from '@angular/core';
import {Block}        from "../../block";
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector   : 'app-tiles',
    styleUrl   : './tiles.component.scss',
    templateUrl: './tiles.component.html',
})
export class TilesComponent extends Block {

    safeURL = this.sanitizer.bypassSecurityTrustUrl('javascript:void(0)');

    constructor(private sanitizer: DomSanitizer) {
        super();
    }
}
