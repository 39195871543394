import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-list',
    templateUrl: './list.component.html',
})
export class ListComponent extends Block {

}
