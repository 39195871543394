<div class="mb-3">
    <div class="flex align-items-center">
        <h3 class="text-900 text-3xl m-0 flex-grow-1" [innerHTML]="block.title | safeHTML"></h3>
        <div class="hidden md:block">
            <app-button *ngFor="let button of buttons" [theme]="theme" [block]="button"
                        blockClass="text-sm"></app-button>
        </div>
    </div>

    <p *ngIf="block.subtitle" [innerHTML]="block.subtitle | safeHTML" class="text-700 text-sm"></p>
</div>
