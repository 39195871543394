import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-stepper',
    templateUrl: './stepper.component.html',
})
export class StepperComponent extends Block {

}
