import {Component, OnInit}       from '@angular/core';
import {Block}                   from "../../block";
import {returnComponents, Theme} from "../../../models/util";
import {BlockComponentModel}     from "../../../models/block";

@Component({
    selector   : 'app-statistics-cta',
    templateUrl: './statistics-cta.component.html',
})
export class StatisticsCtaComponent extends Block implements OnInit {
    statisticBlock?: BlockComponentModel = undefined;

    constructor() {
        super();
        this.theme = new Theme('dark');
    }

    ngOnInit() {
        this.statisticBlock = returnComponents(this.block, false, 'statisticComponent') as BlockComponentModel;
    }
}
