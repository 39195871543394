import {Component}           from '@angular/core';
import {Block}               from "../../block";
import {returnComponents}    from "../../../models/util";
import {BlockComponentModel} from "../../../models/block";

@Component({
    selector   : 'app-usps-block',
    templateUrl: './usps-block.component.html',
})
export class UspsBlockComponent extends Block {
    usps: BlockComponentModel[] = [];

    protected override onChange() {
        super.onChange();

        this.usps = returnComponents(this.block, true, 'usp') as BlockComponentModel[];
    }
}
