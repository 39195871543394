import {Component}           from '@angular/core';
import {Block}               from "../../block";
import {BlockComponentModel} from "../../../models/block";
import {returnComponents}    from "../../../models/util";

@Component({
    selector   : 'app-team',
    templateUrl: './team.component.html',
})
export class TeamComponent extends Block {
    team: BlockComponentModel | null = null;

    protected override onChange() {
        super.onChange();

        this.team = returnComponents(this.block, false, 'team') as BlockComponentModel;
    }
}
