<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTextTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <div [ngClass]="{'bg-brand-color-3': theme.theme === 'dark', 'bg-brand-lightest': theme.theme === 'light'}"
                 class="p-5 md:p-16 rounded-2xl">
                <div class="grid justify-content-between">
                    <div class="col-12 lg:col-6">
                        <div class="h-full pl-0 lg:pl-3">
                            <app-asset *ngIf="block.asset" [block]="block" [theme]="theme"
                                       blockClass="mb-5 md:mb-16"></app-asset>
                            <app-text [block]="block" [theme]="theme"></app-text>
                        </div>
                    </div>
                    <div class="col-12 lg:col-5">
                        <div class="h-full">
                            <div class="flex flex-column justify-content-center h-full" style="border-radius: 6px">
                                <div *ngFor="let usp of usps; let last = last" [ngClass]="{'mb-3': !last}">
                                    <div *ngIf="usp.icon && usp.icon.value !== 'noIcon'"
                                         class="flex justify-content-center align-items-center border-circle bg-brand-color w-14 h-14 z-1 shadow-2 mb-3">
                                        <app-icon [icon]="usp.icon.value" class="text-900 text-base"></app-icon>
                                    </div>

                                    <div [innerHTML]="usp.title | safeHTML"
                                         class="font-bold line-height-3 text-2xl mb-2 [&>p]:mb-0 [&>p]:mt-0"></div>
                                    <div *ngIf="usp.content_limited" [innerHTML]="usp.content_limited | safeHTML"
                                         class="line-height-3 [&>p]:mb-0 [&>p]:mt-0 mb-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
