import {Component, Input} from '@angular/core';

@Component({
    selector   : 'app-icon',
    styleUrl   : './icon.component.scss',
    templateUrl: './icon.component.html',
})
export class IconComponent {
    @Input() icon: string | undefined = '';
    @Input() class: string            = '';
}
