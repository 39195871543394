<div [class]="'z1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' )">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative py-7 ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')">
            <h1 [class]="'text-5xl ' + theme.current.textTitle + ' ' + (title_alignment[block.title_alignment?.value ?? 'left'])"
                [innerHTML]="block.title | safeHTML"></h1>
        </div>
    </div>
</div>
