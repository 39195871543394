import {Directive, Inject, OnInit}                                   from "@angular/core";
import {PageComponentModel}                                          from "../models/page";
import {BlockComponentModel}                                         from "../models/block";
import {Ancestor, Environment, ENVIRONMENT, returnComponents, Theme} from "../models/util";
import {Meta, Title}                                                 from "@angular/platform-browser";
import {Router}                                                      from "@angular/router";
import {HttpClient, HttpErrorResponse}                               from "@angular/common/http";
import {DOCUMENT}                                                    from "@angular/common";
import {TranslateService}                                            from "@ngx-translate/core";
import {GoogleTagManagerService}                                     from "../services/gtm.service";

@Directive()
export abstract class Page implements OnInit {
    page: PageComponentModel | null = null;
    notFound                        = false;
    notFoundTheme                   = new Theme('light')
    canonicalLink: any;

    banner: BlockComponentModel | null = null;
    header!: BlockComponentModel;
    footer!: BlockComponentModel;

    breadcrumbs: BlockComponentModel | null = null;
    ancestors: Ancestor[]                   = [];

    abstract endpoint: string;

    abstract returnUrl(): string

    public constructor(
        @Inject(DOCUMENT) public readonly document: Document,
        @Inject(ENVIRONMENT) public readonly environment: Environment,
        public readonly gtm: GoogleTagManagerService,
        public readonly meta: Meta,
        public readonly title: Title,
        public readonly router: Router,
        public readonly httpClient: HttpClient,
        public readonly translateService: TranslateService
    ) {
    }

    mapPage(page: PageComponentModel, url: string) {
        return page;
    }

    mapBreadcrumbs(page: PageComponentModel) {
        return [...page.ancestors, {title: page.title, url: page.url}];
    }

    pageView(page: PageComponentModel) {
        return {
            event    : 'page_load',
            title    : page.title,
            page_type: 'home',
        }
    }

    ngOnInit(): void {
        const url = this.returnUrl();

        this.httpClient.get<PageComponentModel>(`${this.environment.apiUrl}${this.endpoint}${url}`)
            .subscribe({
                next : (page) => {
                    this.page     = this.mapPage(page, `${this.endpoint}${url}`);
                    this.notFound = false;

                    this.header      = returnComponents({blocks: this.page.global.menu}, false, 'menu') as BlockComponentModel;
                    this.footer      = returnComponents({blocks: this.page.global.footer}, false, 'menu') as BlockComponentModel;
                    this.banner      = returnComponents({blocks: this.page.global.banner}, false, 'banner') as BlockComponentModel;
                    this.breadcrumbs = returnComponents(this.page, false, 'breadcrumbs') as BlockComponentModel;

                    if (this.breadcrumbs) {
                        this.ancestors = this.mapBreadcrumbs(this.page);
                    }

                    const currentLink = this.page.seo?.canonical.find(c => c.locale === this.translateService.currentLang);
                    if (!this.canonicalLink && (this.document && this.document.head.append) && currentLink) {
                        this.canonicalLink = this.document.createElement('link');
                        this.canonicalLink.setAttribute('rel', 'canonical');
                        this.canonicalLink.setAttribute('href', window.location.origin + currentLink.uri);
                        this.document.head.append(this.canonicalLink);
                    }

                    if (this.page.seo) {
                        this.title.setTitle(this.page.seo.title);
                        this.meta.updateTag({name: 'description', content: this.page.seo.description});
                        this.page.seo.canonical.forEach(c => {
                            this.meta.updateTag({
                                rel     : 'alternate',
                                hrefLang: c.locale,
                                href    : window.location.origin + c.uri
                            });
                        });

                        if (this.page.seo.advanced.canonical) {
                            this.canonicalLink.setAttribute('href', this.page.seo.advanced.canonical);
                        }
                    } else {
                        this.title.setTitle(page.title);
                    }

                    this.gtm.trackEvent(this.pageView(this.page));
                },
                error: (err: HttpErrorResponse) => {
                    if (err.status !== 404) {
                        return;
                    }
                    this.notFound = true;

                    this.httpClient.get<PageComponentModel>(`${this.environment.apiUrl}404`)
                        .subscribe({
                            next : (page) => {
                                this.page = {
                                    url      : '',
                                    date     : '',
                                    title    : '',
                                    image    : '',
                                    global   : page.global,
                                    blocks   : [],
                                    section  : '',
                                    content  : '',
                                    ancestors: []
                                }

                                this.title.setTitle('404 | Striive');
                                this.header      = returnComponents({blocks: this.page.global.menu}, false, 'menu') as BlockComponentModel;
                                this.footer      = returnComponents({blocks: this.page.global.footer}, false, 'menu') as BlockComponentModel;
                                this.banner      = returnComponents({blocks: this.page.global.banner}, false, 'banner') as BlockComponentModel;
                                this.breadcrumbs = returnComponents(this.page, false, 'breadcrumbs') as BlockComponentModel;
                            },
                            error: (err: HttpErrorResponse) => {
                            }
                        })
                },
            });
    }
}
