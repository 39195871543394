import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrl   : './statistics.component.scss'
})
export class StatisticsComponent extends Block {

}
