import {Component}                                     from '@angular/core';
import {BarVerticalComponent, calculateViewDimensions} from "@swimlane/ngx-charts";

@Component({
    selector: 'app-bar-chart',
    template: `
        <ngx-charts-chart
                [view]="[width, height]"
                [showLegend]="legend"
                [legendOptions]="legendOptions"
                [activeEntries]="activeEntries"
                [animations]="animations"
                (legendLabelClick)="onClick($event)"
                (legendLabelActivate)="onActivate($event, true)"
                (legendLabelDeactivate)="onDeactivate($event, true)"
        >
            <svg:g [attr.transform]="transform" class="bar-chart chart">
                <svg:g
                        ngx-charts-x-axis
                        *ngIf="xAxis"
                        [xScale]="xScale"
                        [dims]="dims"
                        [showGridLines]="showGridLines"
                        [showLabel]="showXAxisLabel"
                        [labelText]="xAxisLabel"
                        [trimTicks]="trimXAxisTicks"
                        [rotateTicks]="rotateXAxisTicks"
                        [maxTickLength]="maxXAxisTickLength"
                        [tickFormatting]="xAxisTickFormatting"
                        [ticks]="xAxisTicks"
                        [xAxisOffset]="dataLabelMaxHeight.negative"
                        (dimensionsChanged)="updateXAxisHeight($event)"
                ></svg:g>
                <svg:g
                        ngx-charts-y-axis
                        *ngIf="yAxis"
                        [yScale]="yScale"
                        [dims]="dims"
                        [showGridLines]="showGridLines"
                        [showLabel]="showYAxisLabel"
                        [labelText]="yAxisLabel"
                        [trimTicks]="trimYAxisTicks"
                        [maxTickLength]="maxYAxisTickLength"
                        [tickFormatting]="yAxisTickFormatting"
                        [ticks]="yAxisTicks"
                        (dimensionsChanged)="updateYAxisWidth($event)"
                ></svg:g>
                <svg:g
                        ngx-charts-series-vertical
                        [xScale]="xScale"
                        [yScale]="yScale"
                        [colors]="colors"
                        [series]="results"
                        [dims]="dims"
                        [gradient]="gradient"
                        [tooltipDisabled]="tooltipDisabled"
                        [tooltipTemplate]="tooltipTemplate"
                        [showDataLabel]="showDataLabel"
                        [dataLabelFormatting]="dataLabelFormatting"
                        [activeEntries]="activeEntries"
                        [roundEdges]="roundEdges"
                        [animations]="animations"
                        [noBarWhenZero]="noBarWhenZero"
                        (activate)="onActivate($event)"
                        (deactivate)="onDeactivate($event)"
                        (select)="onClick($event)"
                        (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event)"
                ></svg:g>
            </svg:g>
        </ngx-charts-chart>
    `,
})
export class BarChartComponent extends BarVerticalComponent {
    override margin: number[] = [0, 0, 0, 0];

    override update(): void {
        super.update();

        if (!this.showDataLabel) {
            this.dataLabelMaxHeight = {negative: 0, positive: 0};
        }
        this.margin = [14, 24, 14, 24];

        this.dims = calculateViewDimensions({
            width         : this.width,
            height        : this.height,
            margins       : this.margin,
            showXAxis     : this.xAxis,
            showYAxis     : this.yAxis,
            xAxisHeight   : this.xAxisHeight,
            yAxisWidth    : this.yAxisWidth,
            showXLabel    : this.showXAxisLabel,
            showYLabel    : this.showYAxisLabel,
            showLegend    : this.legend,
            legendType    : this.schemeType,
            legendPosition: this.legendPosition
        });

        this.formatDates();

        if (this.showDataLabel) {
            this.dims.height -= this.dataLabelMaxHeight.negative;
        }
        this.xScale = this.getXScale();
        this.yScale = this.getYScale();

        this.setColors();
        this.legendOptions = this.getLegendOptions();

        this.transform = `translate(24, 14)`;
    }
}
