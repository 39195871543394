<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'text-center relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '') "
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <div [class]="'text-base font-bold mb-3 ' + theme.current.textSubtitleContrast">{{ block.subtitle }}</div>

            <span *ngFor="let tag of block.tags; let last = last"
                  [ngClass]="{'mr-3': !last}"
                  class="p-tag p-tag-success text-xs inline-block uppercase">{{ tag.title }}</span>

            <div [class]="'text-5xl font-bold mt-3 mb-5 ' + theme.current.textTitle"
                 [innerHTML]="block.title | safeHTML"></div>

            <img [src]="(block.asset_webp.url ? block.asset_webp.url : block.asset.url)" alt="Image" class="block mx-auto w-full md:w-auto">

            <div [class]="'[&>p]:mb-0 [&>p]:mt-0 line-height-3 mt-5 mb-5 ' + theme.current.content"
                 [innerHTML]="block.content_limited | safeHTML">
            </div>

            <app-button *ngFor="let button of buttons" [block]="button" [theme]="theme" blockClass="mr-0 md:mr-3"></app-button>
        </div>
    </div>
</div>
