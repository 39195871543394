import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-emphasized-post',
    templateUrl: './emphasized-post.component.html',
    styleUrl   : './emphasized-post.component.scss'
})
export class EmphasizedPostComponent extends Block {

}
