import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-testimonials',
    templateUrl: './testimonials.component.html',
    styleUrl   : './testimonials.component.scss'
})
export class TestimonialsComponent extends Block {

}
