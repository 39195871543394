import {Component} from '@angular/core';
import {Block}     from "../block";

@Component({
    selector   : 'app-content',
    templateUrl: './content.component.html',
})
export class ContentComponent extends Block {

}
