<div [class]="'z-1 container m-auto relative overflow-hidden ' + (container ? 'px-3 md:px-6 lg:px-8 xl:!px-42' : '') + ' ' + (componentOffset)">
    <div [class]="'bg-no-repeat px-5 py-5 md:px-6 lg:px-8 relative '
    + (blockClass ? (blockClass + ' ') : '')
    + theme.current.card
    + (block.class_overwrite ? (' ' + block.class_overwrite) : '' )"
         [style]="'border-radius:1rem;'">

        <div class="relative mb-3 md:mb-0 md:absolute w-full h-full left-0 top-0">
            <img *ngIf="block.asset" priority [height]="block.asset.height" [width]="block.asset.width" [ngSrc]="(block.asset_webp.url ? block.asset_webp.url : block.asset.url)"
                 [class]="'block max-w-full h-auto ' + bg_values[block.background_position.value]"
                 alt="">
        </div>

        <div class="flex flex-wrap md:flex-nowrap relative align-items-center justify-content-between overflow-hidden z-1">
            <div *ngIf="firstContentBlock">
                <app-content [block]="firstContentBlock" [theme]="theme"></app-content>
            </div>
            <div class="block w-full md:w-auto">
                <app-button *ngFor="let button of buttons" [block]="button" [theme]="theme"
                            blockClass="mr-0 md:mr-3"></app-button>
            </div>
        </div>
    </div>
</div>
