import {Component} from '@angular/core';
import {Block}     from "../../block";

@Component({
    selector   : 'app-call-to-action',
    templateUrl: './call-to-action.component.html',
    styleUrl   : './call-to-action.component.scss'
})
export class CallToActionComponent extends Block {

}
