<section>
    <div *ngIf="block.tags" class="flex mb-4 gap-2 items-center">
        <ng-container *ngFor="let tag of block.tags; let last = last">
            <span class="text-sm text-orange-500 font-bold">{{tag.title}}</span><span *ngIf="!last" class="w-0.5 h-2.5 bg-gray-500"></span>
        </ng-container>
    </div>

    <div *ngIf="block.content"
         [class]="'mt-0 mb-3 md:mb-4 line-height-3 [&>a]:text-primary [*>a]:no-underline [*>a]:font-bold [&>img]:max-w-full [&>img]:h-auto [&>figure>img]:max-w-full [&>figure>img]:h-auto [&>figure]:m-0 [&>h2]:mb-3 [&>h2]:mt-0 [&>h3]:mb-3 [&>h3]:mt-0 [&>h4]:mb-3 [&>h4]:mt-0 [&>h5]:mb-3 [&>h5]:mt-0 [&>h1]:text-4xl [&>h2]:text-3xl [&>h3]:text-3xl [&>h4]:text-3xl [&>h5]:text-3xl [&>h6]:mt-0 [&>h6]:mb-1 [&>h6]:text-normal ' + theme.current.content"
         [innerHTML]="block.content | safeHTML"></div>

    <div [class]="title_alignment[block.title_alignment?.value ?? 'left']">
        <app-button *ngFor="let button of buttons; let last = last;" [theme]="theme" [block]="button" [blockClass]="' ' + (buttons.length > 1 ? 'mb-3' : '') + ' ' + ((buttons.length > 1 && !last) ? 'mr-0 md:mr-3' : '')"></app-button>
    </div>
</section>
