import {Component, SimpleChanges} from '@angular/core';
import {Block}     from "../../block";
import {Theme} from "../../../models/util";

@Component({
    selector   : 'app-pricing-header',
    templateUrl: './pricing-header.component.html',
})
export class PricingHeaderComponent extends Block {
    public selectedProductType = 0;

    override ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        this.theme = new Theme('dark');
    }
}
