import {Component, Inject, OnInit} from '@angular/core';
import {RouterOutlet}              from '@angular/router';
import {PrimeNGConfig}             from "primeng/api";
import {Meta}                      from "@angular/platform-browser";
import {Environment, ENVIRONMENT}  from "./models/util";
import {environment}               from "../environments/environment";

@Component({
    selector   : 'app-root',
    standalone : true,
    imports    : [RouterOutlet],
    providers  : [
        {
            provide : ENVIRONMENT,
            useValue: environment
        },
    ],
    templateUrl: './app.component.html',
    styleUrl   : './app.component.scss'
})
export class AppComponent implements OnInit {

    constructor(
        @Inject(ENVIRONMENT) readonly environment: Environment,
        private readonly meta: Meta,
        private primengConfig: PrimeNGConfig,
    ) {
    }

    ngOnInit() {
        this.primengConfig.ripple = true;

        this.meta.addTags([
            {name: 'description', content: ''},
            {name: 'robots', content: 'index, follow'},
            {name: 'author', content: ''},
            {charset: 'UTF-8'}
        ]);

        if (environment.production && window) {
            window.console.log   = function () {
            };
            window.console.info  = function () {
            };
            window.console.warn  = function () {
            };
            window.console.error = function () {
            };
            window.console.debug = function () {
            };
        }
    }
}
